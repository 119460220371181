<template>
  <div class="flex justify-center items-center mt-7">
    <div class="mr-3 text-right" style="width: 100px">{{ $t('test.agree') }}</div>
    <div class="gradient-brown-white-blue h-3.5 rounded-lg relative flex items-center">
      <div v-for="position in positions"
           :id="`vertical-line-${position}`"
           :key="position"
           :style="{'left': `${position}%`}"
           class="absolute bg-transparent flex items-center justify-center"
           style="width: 50px; height: 30px; transform: translate(-50%, 0)"
           v-on:mousedown="position_select(position, true)"
           v-on:mouseenter="position_select(position)">
        <div class="white-line bg-white"></div>
      </div>
      <div id="range-knob" :style="{
             'border-color': knob_toggled? 'rgb(244, 244, 244)' : 'white',
             'left': `${position}%`
           }"
           class="absolute range-knob bg-black cursor-pointer"
           style="box-shadow: 0 0 15px 7px rgb(0 0 0 / 0.1)"></div>
    </div>
    <div class="ml-3" style="width: 100px">{{ $t('test.disagree') }}</div>
  </div>
</template>

<style>
.gradient-brown-white-blue {
  width: 500px;
  background: linear-gradient(
      90deg,
      rgb(184, 158, 138) 0%,
      rgb(255, 255, 255) 50%,
      rgb(113, 134, 187) 100%
  );
}

.white-line {
  width: 2px;
  height: 15px;
}

.range-knob {
  width: 38px;
  height: 38px;
  border: 9px solid white;
  border-radius: 50%;
  transform: translate(-50%, 0);
}
</style>

<script>
export default {
  name: "CustomRange",
  data() {
    return {
      knob_toggled: false,
      positions: [5, 25, 50, 75, 95],
      position: 50
    }
  },
  watch: {
    '$store.state.character.selected_range_value': function (newValue) {
      this.position = this.positions[newValue - 1];
    }
  },
  mounted() {
    const knob = document.getElementById('range-knob');

    knob.addEventListener('mousedown', function () {
      this.setKnobToggled(true);
    }.bind(this));
    document.addEventListener('mouseup', function () {
      this.setKnobToggled(false);
    }.bind(this));

    knob.addEventListener('touchstart', function () {
      this.setKnobToggled(true);
    }.bind(this));

    document.addEventListener('touchmove', function (event) {
      this.onMouseMove(event, true);
    }.bind(this), {passive: false});


    document.addEventListener('mousemove', function (event) {
      this.onMouseMove(event, false);
    }.bind(this));

    document.addEventListener('touchend', function () {
      this.setKnobToggled(false);
    }.bind(this));
  },
  methods: {
    onMouseMove(event, touch) {
      if (!this.knob_toggled) {
        return;
      }
      event.preventDefault();
      if (touch && !event.touches) {
        return;
      }
      for (const position of this.positions) {
        const line = document.getElementById(`vertical-line-${position}`);
        const rect = line.getBoundingClientRect();
        const clientX = touch ? event.touches[0].clientX : event.clientX;
        if (clientX >= rect.left && clientX <= rect.right) {
          this.position_select(position);
          return;
        }
      }
    },
    setKnobToggled(value) {
      this.knob_toggled = value;
      document.body.style.userSelect = value ? 'none' : 'auto';
    },
    position_select(position, force = false) {
      if (this.position === position) {
        return;
      }
      if (force || this.knob_toggled) {
        const value = this.positions.indexOf(position) + 1;
        this.position = position;
        this.setKnobToggled(true);
        this.$store.commit('character/set_selected_range_value', {value});
      }
    },
  }
}
</script>