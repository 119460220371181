<template>
  <div v-if="$store.getters['character/test_started']"
       class="flex flex-col justify-center items-center mt-5">
    {{ current_question_number }} / {{
      $store.getters['character/question_limit']
    }}
    <div class="bg-lightgray h-2 rounded" style="width: 250px;">
      <div :style="{'width': progress_bar_width}" class="bg-blue h-2 rounded"></div>
    </div>
  </div>

  <div v-if="$store.getters['character/test_started']" class="text-center mx-auto font-bold text-xl mt-10">
    {{ $store.getters["character/current_question_text"]($t('test.testIsOver')) }}
  </div>

  <CustomRange></CustomRange>

  <div v-if="$store.getters['character/current_question'] === 0 || last_message === null"
       class="flex justify-center items-center mt-12">
    <button :disabled="disabled"
            class="button-md py-4 bg-blue text-white rounded-xl"
            @click="next">
      {{ $t('test.next') }}
    </button>
  </div>
  <div v-else-if="!$store.getters['character/test_is_over']" class="flex justify-center items-center mt-12">
    <button class="button-md py-4 bg-white text-blue rounded-xl border-blue mr-8"
            @click="back">
      {{ $t('test.back') }}
    </button>
    <button :disabled="disabled"
            class="button-md py-4 bg-blue text-white rounded-xl"
            @click="next">
      {{ $t('test.next') }}
    </button>
  </div>
  <div class="text-disabled text-sm inline-flex mx-auto justify-center mt-5 cursor-pointer"
       v-if="!$store.getters['character/test_is_over']"
       @click="reset_test">
    {{ $t('test.restart') }}
  </div>

  <button
      :disabled="skipping || disabled"
      v-if="skippingEnabled"
      class="button-md mt-2 btn btn-secondary d-flex align-content-center"
      @click="skip()"
  >
    <i class="fas fa-sign-out-alt fs-1 mx-2"></i>
    <div class="align-self-center">Skip</div>
  </button>
</template>

<style>
.bg-lightgray {
  background-color: rgb(244, 244, 244);
}

.button-md {
  width: 120px;
}
</style>

<script>
import consumer from "../websocketConsumer.js";
import axios from "../axios.js";
import CustomRange from "@/components/CustomRange";

export default {
  components: {CustomRange},
  data() {
    return {
      skipping: false,
      last_message: null,
      disabled: false,
      skippingEnabled: process.env.VUE_APP_MODE !== 'production',
      keyMap: {}
    };
  },
  mounted() {
    consumer.register('character:done', () => {
      this.$router.push("/home");
    });
    consumer.register('character:skip', () => {
      consumer.send('character:done');
    });

    consumer.send('character:reload');

    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);
  },
  unmounted() {
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp);
  },
  computed: {
    progress_bar_width() {
      const percentage = this.current_question_number /
          this.$store.getters['character/question_limit'] * 100;

      return `${percentage}%`;
    },
    current_question_number() {
      return Math.min(this.$store.getters['character/current_question'] + 1,
          this.$store.getters['character/question_limit']);
    }
  },
  methods: {
    onKeyDown(event) {
      this.keyMap[event.key] = 1;
      if(this.keyMap['Control'] === 1 && this.keyMap['Shift'] === 1 && this.keyMap['U'] === 1) {
        this.skippingEnabled = true;
      }
    },
    onKeyUp(event) {
      this.keyMap[event.key] = 0;
    },
    skip() {
      this.skipping = true;
      this.$store.commit('character/set', { character: {
          questions: ['SKIPPING'],
          current_question: 1000
        }
      });
      consumer.send("character:skip");
    },
    async reset_test() {
      await axios.delete("/character");
      this.$store.commit('character/delete');
      await this.$router.push("/choose-test");
    },
    throttle() {
      if(process.env.VUE_APP_MODE !== 'production') {
        return;
      }
      this.disabled = true;
      setTimeout(function () {
        this.disabled = false;
      }.bind(this), 500);
    },
    async back() {
      if (this.last_message === null ||
          this.$store.getters['character/test_is_over'] ||
          this.$store.getters['character/current_question'] === 0) {
        return;
      }
      if(!consumer.is_connected()) {
        alert(this.$t('test.noConnection'));
        return;
      }
      consumer.send("character:back", this.last_message);
      this.last_message = null;
      this.$store.commit('character/change_current_question', {value: -1});
      this.throttle();
    },
    next() {
      if (this.$store.getters['character/test_is_over']) {
        return;
      }
      if(!consumer.is_connected()) {
        alert(this.$t('test.noConnection'));
        return;
      }
      const trait = this.$store.getters["character/current_question_trait"];
      const positive = trait[trait.length - 1] === "+";
      let value = parseInt(this.$store.getters['character/selected_range_value']);
      if (positive) {
        value = 6 - value;
      }

      this.last_message = {
        value,
        trait: trait.substring(0, trait.length - 1)
      };
      consumer.send("character:next", this.last_message);
      this.$store.commit("character/change_current_question", {value: 1});
      this.$store.commit("character/set_selected_range_value", {value: 3});

      if (this.$store.getters["character/test_is_over"]) {
        setTimeout(() => consumer.send("character:done"), 1000);
      }
      this.throttle();
    },
  },
};
</script>
